<template>
<div id="Skills">

  <div id="Skills__navigation">
    <div id="red-marker"></div>
    <div id="main-nav">
      <div data-name="intro" class="link active">Intro</div>
      <div data-name="design" class="link">Design</div>
      <div data-name="front_end" class="link">Front end</div>
      <div data-name="back_end" class="link">Back end</div>
      <!--      <div data-name="soft_skills" class="link">Soft Skills</div>-->
      <!--      <div data-name="languages" class="link">Languages</div>-->
      <!--      <div data-name="previous_work" class="link">Previous work</div>-->
      <!--      <div data-name="current_work" class="link">Current work</div>-->
      <div data-name="upcoming" class="link">Upcoming</div>
    </div>
  </div>

  <div id="Skills__main">
    <div id="main__content">

      <!--   INTRO   -->
      <div v-if="selected.tab === 'intro'">
        <h1 class="Skills__heading">A little introduction about me...</h1>
        <h6 class="Skills__sub-heading">
          In these pages I have organized and listed some of the many languages, frameworks and tools I have worked with
          over the last few years. I do primarily consider myself a frontend developer, and this is the role I excel
          at. However, I do have some backend experience as well. Since I have worked in start ups I have learned to
          adapt fast to new needs and have the flexibility needed to accomplish any task because of this I would also
          fit well in a full-stack role.
        </h6>
      </div>

      <!--   DESIGN   -->
      <div id="DesignSkills" v-if="selected.tab === 'design'">
        <h1 class="Skills__heading">Design</h1>
        <h6 class="Skills__sub-heading">
          I love good design and beautiful solutions. Aesthetics and usability are important to me and I enjoy to work
          with image manipulation and working on improving user experiences through sketching and prototyping.
        </h6>
        <div class="aSkillGroup">
          <div class="skillGroupHeading">Image manipulation:</div>
          <div class="anItem">
            <div class="anItem__logo photoshop"></div>
            <div class="anItem__label">Photoshop</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo illustrator"></div>
            <div class="anItem__label">Illustrator</div>
          </div>
        </div>

        <div class="aSkillGroup">
          <div class="skillGroupHeading">UI/UX design and prototyping:</div>
          <div class="anItem">
            <div class="anItem__logo xd"></div>
            <div class="anItem__label">Adobe XD</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo sketch"></div>
            <div class="anItem__label">Sketch</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo figma"></div>
            <div class="anItem__label">Figma</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo miro"></div>
            <div class="anItem__label">Miro</div>
          </div>
        </div>
      </div>

      <!--   FRONT END   -->
      <div id="FrontEndSkills" v-if="selected.tab === 'front_end'">
        <h1 class="Skills__heading">Frontend</h1>
        <h6 class="Skills__sub-heading">
          I consider frontend my main strength at the moment. That has been my main focus in my latest projects. I
          am very confident I can deliver good solutions in good time.
        </h6>

        <div class="aSkillGroup">
          <div class="skillGroupHeading">Skills</div>
          <div class="anItem">
            <div class="anItem__logo html"></div>
            <div class="anItem__label">HTML</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo pug"></div>
            <div class="anItem__label">Pug</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo css"></div>
            <div class="anItem__label">CSS</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo stylus"></div>
            <div class="anItem__label">CSS</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo javascript"></div>
            <div class="anItem__label">Javascript</div>
          </div>
        </div>

        <div class="aSkillGroup">
          <div class="skillGroupHeading">Javascript frameworks & libraries:</div>
          <div class="anItem">
            <div class="anItem__logo vue"></div>
            <div class="anItem__label">Vue 2 & 3</div>
            <div class="anItem__label">Vue router</div>
            <div class="anItem__label">Vuex</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo quasar"></div>
            <div class="anItem__label">Quasar</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo react"></div>
            <div class="anItem__label">React</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo jQuery"></div>
            <div class="anItem__label">jQuery</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo three_js"></div>
            <div class="anItem__label">Three.js</div>
          </div>
        </div>

        <div class="aSkillGroup">
          <div class="skillGroupHeading">Visual frameworks:</div>
          <div class="anItem">
            <div class="anItem__logo bootstrap"></div>
            <div class="anItem__label">Bootstrap</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo vuetify"></div>
            <div class="anItem__label">Vuetify</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo thymeleaf"></div>
            <div class="anItem__label">Thymeleaf (Java)</div>
          </div>
        </div>

        <div class="aSkillGroup">
          <div class="skillGroupHeading">Mobile frameworks:</div>
          <div class="anItem">
            <div class="anItem__logo nativescript"></div>
            <div class="anItem__label">Nativescript-vue</div>
          </div>
        </div>
      </div>

      <!--   BACK END   -->
      <div id="BackEndSkills" v-if="selected.tab === 'back_end'">
        <h1 class="Skills__heading">Backend</h1>
        <h6 class="Skills__sub-heading">
          Even if frontend is my main strength I am comfortable enough on the backend to consider myself a full-stack
          developer.
        </h6>

        <div class="aSkillGroup">
          <div class="skillGroupHeading">Skills</div>
          <div class="anItem">
            <div class="anItem__logo rest_api"></div>
            <div class="anItem__label">REST API</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo node"></div>
            <div class="anItem__label">Node</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo google_cloud"></div>
            <div class="anItem__label">Google cloud platform</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo git"></div>
            <div class="anItem__label">Git (Gitlab and Github)</div>
          </div>
        </div>

        <div class="aSkillGroup">
          <div class="skillGroupHeading">Java frameworks:</div>
          <div class="anItem">
            <div class="anItem__logo java"></div>
            <div class="anItem__label">Java</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo java_spring"></div>
            <div class="anItem__label">Java Spring</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo maven"></div>
            <div class="anItem__label">Maven</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo tomcat"></div>
            <div class="anItem__label">Tomcat</div>
          </div>
        </div>

        <div class="aSkillGroup">
          <div class="skillGroupHeading">Databases:</div>
          <div class="anItem">
            <div class="anItem__logo firebase"></div>
            <div class="anItem__label">Firebase</div>
            <div class="anItem__label">Auth</div>
            <div class="anItem__label">Firestore (NoSQL)</div>
          </div>
          <div class="anItem">
            <div class="anItem__logo mySQL"></div>
            <div class="anItem__label">MySQL</div>
          </div>
        </div>

      </div>

      <!--   SKILLS   -->
      <div id="SoftSkills" v-if="selected.tab === 'soft_skills'">
        <h1 class="Skills__heading">My soft skills</h1>

        <div class="aSoftSkill">
          <div class="softSkill__icon adapt"></div>
          <strong>Adaptability</strong>
          <div>While working for start ups I have adapted quickly to rapidly changing work environments.</div>
        </div>
        <div class="aSoftSkill">
          <div class="softSkill__icon detail"></div>
          <strong>Attention to detail</strong>
          <div>I can go fast when needed, but prefer to spend the necessary time to make great solutions.</div>
        </div>
        <div class="aSoftSkill">
          <div class="softSkill__icon communication"></div>
          <strong>Communication</strong>
          <div>I am not afraid to share my opinion</div>
        </div>
        <div class="aSoftSkill">
          <div class="softSkill__icon creativity"></div>
          <strong>Creativity</strong>
          <div>I love to work with design, and innovation which is one of the reasons I have worked with startups and
            used some of my free time in creative groups where we discuss innovative ideas and possibilities.
          </div>
        </div>

        <div class="aSoftSkill">
          <div class="softSkill__icon problem_solving"></div>
          <strong>Problem solving</strong>
          <div>With persistence I have been able to solve problems I have encountered such as when implementing a
            calendar from scratch.
          </div>
        </div>

        <div class="aSoftSkill">
          <div class="softSkill__icon teamwork"></div>
          <strong>Teamwork</strong>
          <div>I have been working together with various team combinations.</div>
        </div>

        <div class="aSoftSkill">
          <div class="softSkill__icon time"></div>
          <strong>Time management</strong>
          <div>I spend my time wisely and I plan my day so that I can keep up with tasks.</div>
        </div>

        <div class="aSoftSkill">
          <div class="softSkill__icon work_ethic"></div>
          <strong>Work ethic</strong>
          <div>I am a very dedicated worker and will give the time and attention needed to accomplish any task.</div>
        </div>

      </div>

      <!--   LANGUAGES   -->
      <div id="Language" v-if="selected.tab === 'languages'">
        <h1 class="Skills__heading">Languages</h1>
        <h6 class="Skills__sub-heading">
          Even though I do not speak German, I began learning and believe I will be able to speak and make myself
          understand on a basic level within 6 months, and I say this from experience because when started leaning
          Norwegian I was able to do customer service while working on Norway within 6 months.
        </h6>
        <div class="Language__content">
          <div class="aLanguage portugal">
            <div class="language__flag"></div>
            <div class="language__label">Portuguese</div>
          </div>
          <div class="aLanguage usa">
            <div class="language__flag"></div>
            <div class="language__label">English</div>
          </div>
          <div class="aLanguage norway">
            <div class="language__flag"></div>
            <div class="language__label">Norwegian</div>
          </div>
          <div class="aLanguage germany">
            <div class="language__flag"></div>
            <div class="language__label">German - learning :)</div>
          </div>
        </div>
      </div>

      <!--   PREVIOUS WORK   -->
      <div id="PreviousWork" v-if="selected.tab === 'previous_work'">
        <div class="references_upon_request">
          Contact references of manager & examples of work will be available upon request from all employers.
        </div>
        <div class="anEmployer">
          <div class="employer__name">NovaBase SA</div>
          <div class="employer__about-company">
            In 30 years, Novabase has become the Portuguese leader in IT, and has been listed on the Euronext Lisbon
            stock exchange since 2000. In 2018, its turnover totalled €148 million, more than half generated outside
            Portugal. Novabase has offices in 6 countries. It boasts more than 2,000 employees of 17 different
            nationalities.
          </div>
          <div class="employer__work-description">
            <strong>Project: </strong> Customs website for the Portuguese Tributary and Customs Authority; <br>
            <div style="margin-top: 20px">
              Main Front End developer for the project, where I worked with Javascript e
              jQuery, and helped with the creation of an MVC structure using Spring and Java for the BE, also worked
              with Thymeleaf, a server-side Java template engine, for the creation of the pages. Also contributed on the
              design of crucial parts of the site.
            </div>
            <div style="margin-top: 20px">
              <strong>Project: </strong> GIA <br>
              <div style="margin-top: 20px">
                <!--Fixme: ask better definition about this project and probably the one above ? -->
                Created a dashboard for the visualization of invoices sent to the government offices.
              </div>
            </div>
          </div>
        </div>
        <div class="anEmployer">
          <div class="employer__name">Naitsmania AS</div>
          <div class="employer__about-company">
            A company that assists start up companies to go from idea to production.
          </div>
          <div class="employer__work-description">
            I was part of a smaller team that were working on new innovative ideas, planning new functionality and
            discovering opportunities for a variety of business cases. I was also involved in conceptualizing and
            developing some of these concepts.
          </div>
        </div>
      </div>

      <!--   CURRENT WORK   -->
      <div id="CurrentWork" v-if="selected.tab === 'current_work'">
        <div class="references_upon_request">
          Contact references of manager and CEO & examples of work will be available upon request from all employers.
        </div>
        <div class="anEmployer">
          <div class="employer__name">
            Way AS
            <span class="main_employer">Main Employer</span>
          </div>
          <div class="employer__about-company">
            Norwegian startup that will revolutionize driving lessons and the way people learn how to drive.
          </div>
          <div class="employer__work-description">
            <p>
              I had the main responsibility for designing and prototyping in XD frontend solutions for 2 ERP (Enterprise
              Resource Planning) dashboards for both the "Administrators" and "Instructors" of the company, as well as
              producing some designs for the mobile app.
            </p>

            <p>
              I also was responsible for developing those same designs, into production ready websites enabling the
              company "Administrators" and "Instructors" to accomplish their tasks with ease. One of the solutions I
              made was a calendar that was built from scratch to be more customizable and fit the particular users use
              cases based on findings in the design and prototyping stages.
            </p>

            <p>
              My current work at this company involves analyzing the feedback given from the company admins and
              instructors, and improve current features and implement new functionality on the websites based on the
              feedback given.
            </p>
          </div>
        </div>
        <div class="anEmployer">
          <div class="employer__name">Indigo AS</div>
          <div class="employer__about-company">
            Norwegian startup that is working on matching craftsmen with customers
          </div>
          <div class="employer__work-description">
            I am the main frontend developer where I am involved in planning some of the functionality and developing
            solutions for the frontend.
          </div>
        </div>
        <!--        <div class="anEmployer">-->
        <!--          <div class="employer__name">teamwork.com</div>-->
        <!--          <div class="employer__about-company">-->
        <!--            Teamwork needs no introduction, as its used by millions worldwide.-->
        <!--          </div>-->
        <!--          <div class="employer__work-description">-->
        <!--            After using its software for a while I was able to identify some areas where there could be made-->
        <!--            improvements, after reaching out to them, we were able to agree on what could be made, so my current work-->
        <!--            involves improving, manly two things, the calendar using my custom build calendar solution to replace the-->
        <!--            fullcalendar version they have, and to replace the tree of tasks they have for one that was also developed-->
        <!--            by me.-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

      <!--   UPCOMING   -->
      <div id="Upcoming" v-if="selected.tab === 'upcoming'">
        <h1 class="Skills__heading">Personal projects of mine...</h1>
        <h6 class="Skills__sub-heading">
          Here are some of the projects I am developing with plans of releasing them in GitHub, almost all of these
          projects were started out of need because I was trying to find a better solution and simply could not find it,
          so I decided to build my own and the result is quite amazing I must say.
        </h6>
        <div class="anUpcoming">
          <div class="upcoming__image calendar"></div>
          <div class="upcoming__text-wrapper">
            <div class="upcoming__label">Calendar Z</div>
            <div class="upcoming__description">The project I am most proud. :) Its a custom made calendar solution that
              I created for time management, it is fully customizable, with possibility to add time validators and snap
              into place functionality, among others, the plan for this Calendar is to surpass Fullcalendar in features
              and quality, which by tha way I believe it already does :)
            </div>
          </div>
        </div>
        <div class="anUpcoming">
          <div class="upcoming__image tree"></div>
          <div class="upcoming__text-wrapper">
            <div class="upcoming__label">Tree Y</div>
            <div class="upcoming__description">I build a performant tree visualization using vanilla JS, after finding
              out that there was no good solution out there with the same functionalities I was looking for, this tree
              is useful for sorting data according to a relation parent / descendant.
            </div>
          </div>
        </div>
        <div class="anUpcoming">
          <div class="upcoming__image chat"></div>
          <div class="upcoming__text-wrapper">
            <div class="upcoming__label">Chat X</div>
            <div class="upcoming__description">I also build a chat, that I am still improving and working on. I am using
              a NoSql database structure (Firestore) to store the messages and all related information, and even
              thought I am still working on some final details I plan to finish the chat very soon, including the
              possibility to have multiple servers (like Discord), and also add some WebRTC video and screen sharing as
              well.
            </div>
          </div>
        </div>
        <div class="upcoming__bottom-description">
          The names are X Y Z, because I dont know what name these projects should have :)
        </div>
        <div class="upcoming__bottom-description">
          All these projects can be demonstrated live upon request.
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import { onMounted, reactive } from 'vue';

export default {
  name: 'Skills',
  setup () {

    const selected = reactive({
      tab: 'intro',
    });

    onMounted(() => {
      let redMarker = document.getElementById('red-marker');
      let container = document.getElementById('Skills__navigation');
      let active = document.querySelector('#main-nav .link.active');
      let _active = active.getBoundingClientRect();
      redMarker.style.height = `${_active.height}px`;

      let links = document.querySelectorAll('#main-nav .link');
      ;[...links].forEach(link => {

        link.onpointerdown = () => {
          let active = document.querySelector('#main-nav .link.active');
          active && active.classList.remove('active');
          selected.tab = link.getAttribute('data-name');
          link.classList.add('active');
        };

        link.onpointerenter = () => {
          let _link = link.getBoundingClientRect();
          let _container = container.getBoundingClientRect();
          redMarker.style.transform = `translateY(${_link.top - _container.top}px)`;
          redMarker.style.height = `${_link.height}px`;
        };

        link.onpointerout = () => {
          let active = document.querySelector('#main-nav .link.active');
          if (active) {
            let _active = active.getBoundingClientRect();
            let _container = container.getBoundingClientRect();
            redMarker.style.transform = `translateY(${_active.top - _container.top}px)`;
            redMarker.style.height = `${_active.height}px`;
          }
        };
      });
    });

    return {
      selected,
    };

  },
};
</script>

<style lang="stylus" scoped>
#Skills
  position absolute
  overflow hidden
  z-index 100
  top 70px
  right 70px
  bottom 70px
  left 70px
  border-radius 20px
  box-shadow 0 10px 30px 0 rgba(37, 44, 97, 0.15), 0 4px 18px 0 rgba(93, 100, 148, 0.2)
  #Skills__navigation
    position absolute
    z-index 100
    top 0
    left 0
    bottom 0
    right auto
    width 200px
    #red-marker
      position absolute
      z-index 100
      top 0
      left 0
      width 100%
      height 30px
      background rgba(255, 0, 0, 0.1)
      border-right 3px solid red
      transition transform .2s linear
      pointer-events none
    #main-nav
      box-shadow inset 0 10px 30px 0 rgba(37, 44, 97, 0.15), inset 0 0 15px 0 rgba(93, 100, 148, 0.2)
      background rgba(229, 246, 255, 0.8);
      position absolute
      top 0
      left 0
      bottom 0
      right 0
      display flex
      align-items center
      flex-direction column
      justify-content space-evenly
      .link
        text-transform uppercase
        flex 1
        width 100%
        display flex
        align-items center
        justify-content center
        font 500 12px Averta, sans-serif;
        letter-spacing 1px
        cursor pointer
        color #002289
        &.active
          color red
  #Skills__main
    background #F0F5FA
    //background #f8f6f4
    position absolute
    top 0
    right 0
    bottom 0
    left 200px
    overflow-x hidden
    overflow-y auto
    display flex
    align-items stretch
    #main__content
      width 50%
      flex 1
      padding 0 50px
      overflow-x auto

.Skills__heading
  font 500 32px Averta, sans-serif;
  margin 50px auto 20px
  text-align left
  width 80%

.Skills__sub-heading
  font 400 20px / 30px Averta, sans-serif;
  margin 20px auto 40px auto
  text-align left
  width 80%

#FrontEndSkills, #DesignSkills, #BackEndSkills
  .aSkillGroup
    background white
    padding 20px
    border-radius 20px
    text-align left
    display flex
    align-items center
    justify-content space-evenly
    flex-wrap wrap
    width 80%
    margin 30px auto
    .skillGroupHeading
      text-align center
      width 100%
      font italic 400 15px Averta, sans-serif;
      color grey
      margin-bottom 20px
    .anItem
      align-self stretch
      padding 5px 20px
      display flex
      flex-direction column
      align-items center
      justify-content flex-start
      .anItem__logo
        width 40px
        height 40px
        background-repeat no-repeat
        background-position center
        background-size contain
        margin-bottom 10px
        &.photoshop
          background-image url("../../public/skills/Adobe_Photoshop_CC_icon.svg")
        &.illustrator
          background-image url("../../public/skills/Adobe_Illustrator_CC_icon.svg")
        &.xd
          background-image url("../../public/skills/Adobe_XD_CC_icon.svg")
        &.sketch
          background-image url("../../public/skills/sketch.svg")
        &.figma
          background-image url("../../public/skills/figma.svg")
        &.miro
          background-image url("../../public/skills/miro.svg")
        &.html
          background-image url("../../public/skills/html5.svg")
        &.pug
          background-image url("../../public/skills/pug.svg")
        &.css
          background-image url("../../public/skills/css3.svg")
        &.javascript
          background-image url("../../public/skills/javascript_shield.svg")
        &.vue
          background-image url("../../public/skills/vue_js.svg")
        &.quasar
          background-image url("../../public/skills/quasar.svg")
        &.react
          background-image url("../../public/skills/react.svg")
        &.jQuery
          background-image url("../../public/skills/jquery.svg")
        &.three_js
          background-image url("../../public/skills/three_js.svg")
        &.bootstrap
          background-image url("../../public/skills/bootstrap.svg")
        &.vuetify
          background-image url("../../public/skills/vuetify.svg")
        &.stylus
          background-image url("../../public/skills/stylus.svg")
        &.nativescript
          background-image url("../../public/skills/nativescript.svg")
        &.thymeleaf
          background-image url("../../public/skills/thymeleaf.png")
        &.rest_api
          background-image url("../../public/skills/rest_api.svg")
        &.node
          background-image url("../../public/skills/node_js.svg")
        &.google_cloud
          background-image url("../../public/skills/google_cloud.svg")
        &.git
          background-image url("../../public/skills/git.svg")
        &.java
          background-image url("../../public/skills/java.svg")
        &.java_spring
          background-image url("../../public/skills/java_spring.svg")
        &.firebase
          background-image url("../../public/skills/firebase.svg")
        &.tomcat
          background-image url("../../public/skills/tomcat.svg")
        &.maven
          width 80px
          background-image url("../../public/skills/maven.svg")
        &.mySQL
          width 100px
          background-image url("../../public/skills/mySQL.svg")
          background-size cover
      .anItem__label
        font italic 400 14px Averta, sans-serif;

#SoftSkills
  .aSoftSkill
    position relative
    text-align left
    width 80%
    margin 30px auto
    padding-left 60px
    .softSkill__icon
      position absolute
      top 0
      left 0
      width 40px
      height 30px
      background-repeat no-repeat
      background-position center
      background-size contain
      &.adapt
        background-image url("../../public/skills/soft/support.svg")
      &.detail
        background-image url("../../public/skills/soft/search.svg")
      &.communication
        background-image url("../../public/skills/soft/chat.svg")
      &.creativity
        background-image url("../../public/skills/soft/lightbulb.svg")
      &.problem_solving
        background-image url("../../public/skills/soft/problem-solving.svg")
      &.teamwork
        background-image url("../../public/skills/soft/united.svg")
      &.time
        background-image url("../../public/skills/soft/clock.svg")
      &.work_ethic
        background-image url("../../public/skills/soft/values.svg")
    strong
      color black

#Language
  .Language__content
    display flex
    flex-wrap wrap
    .aLanguage
      width 50%
      padding 30px
      .language__flag
        margin 0 auto
        height 35px
        width 35px
      .language__label
        font-weight 500
        margin-top 15px
      &.portugal
        .language__flag
          background-image url('../components/skills/icons/portugal.svg')
      &.usa
        .language__flag
          background-image url('../components/skills/icons/united-states.svg')
      &.norway
        .language__flag
          background-image url('../components/skills/icons/norway.svg')
      &.germany
        .language__flag
          background-image url('../components/skills/icons/germany.svg')

#PreviousWork, #CurrentWork
  padding 50px 0 30px 0
  position relative
  text-align left
  .references_upon_request
    font 400 14px Averta, sans-serif;
    top 30px
    right 0
    width 450px
    padding 10px 20px
    position absolute
    border-radius 10px 20px
    background rgba(65, 105, 225, 0.08)
    color black
  .anEmployer
    width 80%
    margin 10px auto 0 auto
    .employer__name
      font 500 27px Averta, sans-serif;
      margin-bottom 20px
      color black
      .main_employer
        margin-left 10px
        font-size 12px
        color gray
    .employer__about-company
      font-size 13px
      color gray
    .employer__work-description
      padding 20px
      color black
      p
        margin 0 0 20px 0

#Upcoming
  font 400 13px Averta, sans-serif;
  .anUpcoming
    width 80%
    margin 50px auto
    display flex
    .upcoming__image
      min-width 220px
      height 120px
      background-repeat no-repeat
      background-position top center
      background-size 100%
      border-radius 5px
      border 5px solid white
      box-shadow 0 0 3px 0 rgba(0, 0, 0, 0.3), 0 0 7px 0 rgba(0, 0, 0, 0.1)
      &.calendar
        background-image url('../../public/skills/upcoming/calendar.png')
      &.tree
        background-image url('../../public/skills/upcoming/tree.png')
      &.chat
        background-image url('../../public/skills/upcoming/chat.png')
    .upcoming__text-wrapper
      padding-left 40px
      text-align left
      .upcoming__label
        font 500 18px Averta, sans-serif;
      .upcoming__description
        font 400 15px / 20px Averta, sans-serif;
        flex 1
  .upcoming__bottom-description
    color gray

</style>
